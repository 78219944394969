<template>
  <span
    class="atoms-date"
    :style="{ animationDelay: delay + 's' }"
  >
    <LazyAtomsIcon v-if="icon" :name="icon" />
    {{ before ? `${before} ` : `` }}{{ dayjs(date).format('dddd Do MMMM YYYY') }}
  </span>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)

defineProps({
  date: String,
  before: String,
  icon: String,
  delay: { type: Number, default: 0 },
})
</script>

<style lang="scss">
.atoms-date {
  position: relative;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;

  .icon {
    margin-bottom: -0.12em;
  }
}
</style>
